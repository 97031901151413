/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "@fontsource/aleo/300.css";
import "@fontsource/aleo/400.css";
import "@fontsource/aleo/700.css";


const scrollTo = (id) => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo({left: 0, top: el.offsetTop - 60, behavior: 'smooth'}) // offset = clearance for header
  return false
}

export const onRouteUpdate = ({ location }) => {
    if (location.hash) {
      window.setTimeout(scrollTo(location.hash), 10)
    }

    // // Party Town
    // if (process.env.NODE_ENV !== 'production') {
    //     return null;
    // }
    // const pagePath = location ? location.pathname + location.search + location.hash : undefined;
    // setTimeout(() => {
    //     if (typeof window.gtag === 'function') {
    //         window.gtag('event', 'page_view', { page_path: pagePath });
    //     }
    // }, 100);
};
